import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Mention from "@tiptap/extension-mention";

Alpine.data("wysiwygEditor", (modelContent, toggleButtons = false) => {
  let editor;
  return {
    content: modelContent,
    toggleButtons: toggleButtons,
    showButtons: false,
    updatedAt: Date.now(),
    init(element) {
      editor = new Editor({
        element: element,
        extensions: [StarterKit, Image, Mention],
        editorProps: {
          attributes: {
            class:
              "wysiwyg h-full max-h-[33vh] px-4 pt-3.5 pb-0 mb-0 break-all focus:outline-none w-full max-w-full resize-x-none overflow-y-auto resize-auto",
          },
        },
        content: this.content,
        onUpdate: ({ editor }) => {
          this.content = editor.getHTML();
        },
      });

      this.$watch("content", (content) => {
        // If the new content matches TipTap's, then we just skip.
        if (content === editor.getHTML()) return;

        /*  overflow-y-auto
                  Otherwise, it means that a force external to TipTap
                  is modifying the data on this Alpine component,
                  which could be Livewire itself.
                  In this case, we just need to update TipTap's
                  content and we're good to do.
                  For more information on the `setContent()` method, see:
                    https://www.tiptap.dev/api/commands/set-content
                */
        editor.commands.setContent(content, false);
      });
    },
    getShowButtons()
    {
      return !this.getShowToggle() || this.showButtons;
    },
    getShowToggle()
    {
      return this.toggleButtons;
    },
    toggle()
    {
      this.showButtons = !this.showButtons;
    },
    isActive(type, opts = {}) {
      return editor.isActive(type, opts);
    },
    toggleParagraph() {
      editor.chain().focus().setParagraph().run();
    },
    toggleOrderedList() {
      editor.chain().focus().toggleOrderedList().run();
    },
    toggleBulletList() {
      editor.chain().focus().toggleBulletList().run();
    },
    toggleHeading(opts) {
      editor.chain().toggleHeading(opts).focus().run();
    },
    toggleBold() {
      editor.chain().toggleBold().focus().run();
    },
    toggleItalic() {
      editor.chain().toggleItalic().focus().run();
    },
    undo() {
      editor.chain().focus().undo().run();
    },
    redo() {
      editor.chain().focus().redo().run();
    },
  };
});
