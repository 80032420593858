import { isNumber } from "@tiptap/core";

/**
 * Returns the original Title
 * @type {null}
 */
let originalTitle = null;
Livewire.on('update-page-title', (data) => {
  originalTitle = data.title;
})
const getOriginalTitle = () => {
  if(!originalTitle) {
    originalTitle = document.title;
  }

  return originalTitle;
}

/**
 * Sets the page title.
 *
 * @param {null} title - The title of the page.
 *
 * @returns {void}
 */
const setPageTitle = (title) => {
  document.title = title;
};

/**
 * Creates a Time Tracker object.
 *
 * @param {Object} stamps
 * @returns {Object} - The Time Tracker object.
 */
export default () => {
  return {
    initialDateTime: null,
    currentEvent: null,
    pausedTime: 0,
    pauseStart: null,
    firstStamp: null,
    latestStamp: null,
    hours: "00",
    minutes: "00",
    seconds: "00",
    totalHours: "00",
    totalMinutes: "00",
    totalSeconds: "00",
    pageTitleRegex: /\d{2}:\d{2}:\d{2}/,
    timerInterval: null,
    offsetInSeconds: 0,
    init() {
      getOriginalTitle(); //ensure original title is stored
      this.offsetInSeconds = new Date().getTimezoneOffset() * 60;
      this.$watch('timestamps', (timestamps) => this.processTimestamps(timestamps));
      this.processTimestamps(this.timestamps);
    },
    startTimerIfNotRunning() {
      if(!this.timerInterval) {
        this.timerInterval = setInterval(() => this.tick(), 1000);
      }
    },
    stopTimer() {
      setPageTitle(getOriginalTitle());
      clearInterval(this.timerInterval);
    },
    processTimestamps(timestamps) {
      if(!timestamps) {
        return;
      }

      this.firstStamp = timestamps[0];
      this.latestStamp = timestamps[timestamps.length - 1];
      this.initialDateTime = this.firstStamp['timestamp'];

      this.processLatestStamp();

      if(this.currentEvent !== 'stop') {
        this.startTimerIfNotRunning();
      } else {
        this.stopTimer();
      }
    },
    processLatestStamp() {
      // divided by one, because ide cries that date is not a number
      let timestamp = this.latestStamp.timestamp;
      this.currentEvent = this.latestStamp.stamp_type;

      switch (this.currentEvent) {
        case "pause":
          this.pauseStart = timestamp;
          console.log(`Pause started at: ${this.pauseStart}`);
          break;
        case "resume":
          if (this.pauseStart) {
            this.pausedTime += (timestamp - this.pauseStart);
            this.pauseStart = null;
            console.log(`Pause ended at: ${timestamp}\nTotal paused time: ${this.pausedTime}`);
          }
          break;
        case "stop":
          this.stopTimer();
      }
    },
    tick() {
      if(this.currentEvent === null) {
        return;
      }

      if(this.currentEvent === "stop") {
        this.stopTimer();
        return;
      }

      let stampNow = Math.floor(Date.now()/ 1000);
      let elapsedTimeTotal = stampNow - this.firstStamp['timestamp'];
      let activePausedTime = this.pauseStart ? (stampNow - this.pauseStart) : 0;
      let elapsedTime = elapsedTimeTotal - activePausedTime - this.pausedTime;

      this.hours = Math.floor(elapsedTime / 60 / 60)
        .toString()
        .padStart(2, "0");
      this.minutes = Math.floor((elapsedTime % (60 * 60)) / 60)
        .toString()
        .padStart(2, "0");
      this.seconds = Math.floor((elapsedTime % 60))
        .toString()
        .padStart(2, "0");

      this.totalHours = Math.floor(elapsedTimeTotal / 60 / 60)
        .toString()
        .padStart(2, "0");
      this.totalMinutes = Math.floor((elapsedTimeTotal % (60 * 60)) / 60)
        .toString()
        .padStart(2, "0");
      this.totalSeconds = Math.floor((elapsedTimeTotal % 60))
        .toString()
        .padStart(2, "0");

      this.updatePageTitle();
    },
    getTitleEmoji() {
      switch (this.currentEvent) {
        case "pause":
          return "⏸️";
        default:
          return "🔴";
      }
    },
    updatePageTitle() {
      setPageTitle(
        `${this.getTitleEmoji()} ${this.hours}:${this.minutes}:${this.seconds} - ${getOriginalTitle()}`
      );
    },
  };
};
