window.layoutColors = class {
  static layoutBackgrounds = {
    BlueEclipse:
      "bg-[radial-gradient(ellipse_at_left,_var(--tw-gradient-stops))] from-cyan-500 bg-cyan-500 via-blue-600 to-sky-600 ",
    GreenOcean:
      "bg-gradient-to-br from-cyan-400 bg-cyan-400 via-teal-600 to-teal-800",
    MarbleWhite: "bg-white ",
    BlackPerl:
      "bg-gradient-to-tr from-black bg-black via-violet-900/40 to-black ",
    BlueAtmosphere: "bg-gradient-to-tr from-cyan-500 to-blue-500 ",
    SunsetOrange:
      "bg-gradient-to-br from-orange-400 bg-orange-400 to-rose-400 ",
    DemonRed: "bg-gradient-to-br from-red-500 bg-red-500 to-red-800 ",
    SunriseOrange:
      "bg-gradient-to-b from-orange-500 bg-orange-500 to-yellow-600 ",
    GrapefruitOrange:
      "bg-gradient-to-t from-yellow-600 bg-yellow-600 to-red-600 ",
    DeepBlueEclipse:
      "bg-[radial-gradient(ellipse_at_right,_var(--tw-gradient-stops))] from-sky-400 bg-sky-400 to-indigo-900 ",
    IndigoCyan: "bg-gradient-to-r from-indigo-500 bg-indigo-500 to-cyan-500 ",
    CardboardBrown:
      "bg-gradient-to-tr from-yellow-700 bg-yellow-700 to-yellow-800 ",
    CyanBlue: "bg-gradient-to-tr from-cyan-500 from-cyan-500 to-blue-500 ",
    WishfulTeal: "bg-gradient-to-tr from-cyan-400 bg-cyan-400 to-teal-400 ",
    NightmareTeal: "bg-gradient-to-r from-cyan-600 bg-cyan-600 to-teal-800 ",
    ScarletRed: "bg-gradient-to-r from-red-400 bg-red-400 to-red-900 ",
    HotPink: "bg-gradient-to-br from-fuchsia-600 bg-fuchsia-600 to-pink-600 ",
    EmeraldGreen:
      "bg-gradient-to-r from-emerald-500 bg-emerald-500 to-emerald-900 ",
    NightClubPink: "bg-gradient-to-r from-pink-500 bg-pink-500 to-violet-600 ",
    CarbonGray: "bg-gradient-to-br from-slate-600 bg-slate-600 to-slate-900 ",
    LuciaOrange: "bg-gradient-to-r bg-amber-500 from-amber-500 to-yellow-700 ",
    DeepOceanBlue: "bg-gradient-to-r bg-blue-700 from-blue-700 to-blue-950 ",
    EconomyTurquoise:
      "bg-gradient-to-br bg-[#019aaa] from-[#019aaa]/50 via-[#019aaa] to-[#019aaa]/25 ",
    BlueViolet: "bg-gradient-to-r from-blue-600 bg-blue-600 to-violet-600 ",
    Graydient: "bg-gradient-to-b bg-gray-900 from-gray-900 to-gray-600"
  };

  static layoutBackgroundTextColors = {
    BlueEclipse: "dark:text-cyan-500 text-cyan-500",
    GreenOcean: "dark:text-cyan-400 text-cyan-400",
    MarbleWhite: "dark:text-white text-white",
    BlackPerl: "dark:text-black text-black",
    BlueAtmosphere: "dark:text-sky-400 text-sky-400",
    SunsetOrange: "dark:text-orange-400 text-orange-400",
    DemonRed: "dark:text-red-500 text-red-500",
    SunriseOrange: "dark:text-orange-500 text-orange-500",
    GrapefruitOrange: "dark:text-yellow-600 text-yellow-600",
    DeepBlueEclipse: "dark:text-sky-400 text-sky-400",
    IndigoCyan: "dark:text-indigo-500 text-indigo-500",
    CardboardBrown: "dark:text-yellow-700 text-yellow-700",
    CyanBlue: "dark:text-cyan-500 text-cyan-500",
    WishfulTeal: "dark:text-cyan-400 text-cyan-400",
    NightmareTeal: "dark:text-cyan-600 text-cyan-600",
    ScarletRed: "dark:text-red-400 text-red-400",
    HotPink: "dark:text-fuchsia-600 text-fuchsia-600",
    EmeraldGreen: "dark:text-emerald-500 text-emerald-500",
    NightClubPink: "dark:text-pink-500 text-pink-500",
    CarbonGray: "dark:text-slate-600 text-slate-600",
    LuciaOrange: "dark:text-amber-500 text-amber-500",
    DeepOceanBlue: "dark:text-blue-700 text-blue-700",
    EconomyTurquoise: "dark:text-[#019aaa] text-[#019aaa]",
    BlueViolet: "dark:text-blue-600 text-blue-600",
    Graydient: "dark:text-gray-200 text-gray-900"
  };

  static layoutBackgroundOverlays = {
    BlueEclipse: "dark:bg-zinc-950/50",
    GreenOcean: "dark:bg-zinc-950/50",
    MarbleWhite: "dark:opacity-60",
    BlackPerl: "dark:opacity-0",
    BlueAtmosphere: "dark:bg-zinc-950/50",
    SunsetOrange: "dark:bg-zinc-950/50",
    DemonRed: "dark:bg-zinc-950/50",
    SunriseOrange: "dark:bg-zinc-950/50",
    GrapefruitOrange: "dark:bg-zinc-950/50",
    DeepBlueEclipse: "dark:bg-zinc-950/50",
    IndigoCyan: "dark:bg-zinc-950/50",
    CardboardBrown: "dark:bg-zinc-950/50",
    CyanBlue: "dark:bg-zinc-950/50",
    WishfulTeal: "dark:bg-zinc-950/50",
    NightmareTeal: "dark:bg-zinc-950/50",
    ScarletRed: "dark:bg-zinc-950/50",
    HotPink: "dark:bg-zinc-950/50",
    EmeraldGreen: "dark:bg-zinc-950/50",
    NightClubPink: "dark:bg-zinc-950/50",
    CarbonGray: "dark:bg-zinc-950/50",
    LuciaOrange: "dark:bg-zinc-950/50",
    DeepOceanBlue: "dark:bg-zinc-950/50",
    EconomyTurquoise: "dark:bg-zinc-950/50",
    BlueViolet: "dark:bg-zinc-950/50",
    Graydient: "dark:bg-gray-900/50"
  };

  static getOverlay(colorName) {
    return this.layoutBackgroundOverlays[colorName] || colorName;
  }

  static getTextColor(colorName) {
    return this.layoutBackgroundTextColors[colorName];
  }

  static getTextColorAsArray(colorName) {
    return this.toArray(this.getTextColor(colorName));
  }

  static get(colorName) {
    return this.layoutBackgrounds[colorName] || colorName;
  }

  static getAsArray(colorName) {
    return this.toArray(this.get(colorName));
  }

  static getAsStaticVersion(colorName) {
    const colorValue = this.get(colorName);
    const colorRegex =
      /^(bg-(white|yellow|amber)|from-(white|yellow|amber|rose|pink|indigo|violet|purple|fuchsia))/i;
    const isLikelyLightColor = colorRegex.test(colorValue);

    const staticColorMatch = colorValue.match(/from-([a-z0-9-]+)/);
    const staticColor = staticColorMatch
      ? `bg-${staticColorMatch[1]}`
      : colorValue;
    const textColor = isLikelyLightColor ? "text-black/90" : "text-white/90";

    return `${staticColor} ${textColor}`;
  }

  static getAsStaticVersionArray(colorName) {
    return this.toArray(this.getAsStaticVersion(colorName));
  }

  static toArray(stringClass) {
    return stringClass.split(" ").filter(Boolean);
  }

  static getArcThemeColors() {
    const rootStyles = getComputedStyle(document.documentElement);

    return {
      arcBackgroundGradientOverlayColor1: rootStyles.getPropertyValue('--arc-background-gradient-overlay-color1').trim(),
      arcBackgroundGradientColor0: rootStyles.getPropertyValue('--arc-background-gradient-color0').trim(),
      arcPaletteFocus: rootStyles.getPropertyValue('--arc-palette-focus').trim(),
      arcPaletteCutoutColor: rootStyles.getPropertyValue('--arc-palette-cutoutColor').trim(),
      arcBackgroundGradientColor1: rootStyles.getPropertyValue('--arc-background-gradient-color1').trim(),
      arcPaletteHover: rootStyles.getPropertyValue('--arc-palette-hover').trim(),
      arcPaletteTitle: rootStyles.getPropertyValue('--arc-palette-title').trim(),
      arcPaletteMaxContrastColor: rootStyles.getPropertyValue('--arc-palette-maxContrastColor').trim(),
      arcPaletteMinContrastColor: rootStyles.getPropertyValue('--arc-palette-minContrastColor').trim(),
      arcPaletteBackgroundExtra: rootStyles.getPropertyValue('--arc-palette-backgroundExtra').trim(),
      arcPaletteSubtitle: rootStyles.getPropertyValue('--arc-palette-subtitle').trim(),
      arcPaletteBackground: rootStyles.getPropertyValue('--arc-palette-background').trim(),
      arcPaletteForegroundSecondary: rootStyles.getPropertyValue('--arc-palette-foregroundSecondary').trim(),
      arcBackgroundGradientOverlayColor0: rootStyles.getPropertyValue('--arc-background-gradient-overlay-color0').trim(),
      arcPaletteForegroundTertiary: rootStyles.getPropertyValue('--arc-palette-foregroundTertiary').trim(),
      arcPaletteForegroundPrimary: rootStyles.getPropertyValue('--arc-palette-foregroundPrimary').trim(),
    };
  }
};
