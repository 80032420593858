Alpine.data('barcodeScanner', () => ({
  isInitialized: false,
  startedCamera: false,
  foundCode: false,
  devices: [],
  currentDeviceIndex: 0,
  detectedResults: [],

  async startScanner() {
    if (!this.startedCamera) {
      this.startedCamera = true;
      await this.initScanner();
    } else if (this.isInitialized) {
      Quagga.start();
    }
  },

  async initScanner(deviceId = null) {
    if (this.isInitialized) {
      Quagga.stop();
    }

    const constraints = deviceId
      ? { deviceId: { exact: deviceId }, width: { ideal: 1280 }, height: { ideal: 720 } }
      : { facingMode: "environment", width: { ideal: 1280 }, height: { ideal: 720 } };

    Quagga.init({
      locate: true,
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: document.getElementById("interactive"),
        constraints,
        willReadFrequently: true
      },
      decoder: {
        readers: ["ean_reader", "code_128_reader", "upc_reader"],
        debug: {
          drawBoundingBox: true,
          showFrequency: true,
          drawScanline: true,
          showPattern: true
        }
      }
    }, async (err) => {
      if (err) {
        console.error("Quagga init error:", err);
        alert("Fehler beim Initialisieren der Kamera!");
        return;
      }

      this.isInitialized = true;
      Quagga.start();
      await this.loadDevices();
      this.setupDetection();
    });
  },

  async loadDevices() {
    const allDevices = await navigator.mediaDevices.enumerateDevices();
    this.devices = allDevices.filter(d => d.kind === "videoinput");
    const backCamIndex = this.devices.findIndex(d => d.label.toLowerCase().includes("back"));
    this.currentDeviceIndex = backCamIndex !== -1 ? backCamIndex : 0;
  },

  async switchCamera() {
    if (this.devices.length < 2) return;

    this.currentDeviceIndex = (this.currentDeviceIndex + 1) % this.devices.length;
    const selectedDevice = this.devices[this.currentDeviceIndex];
    await this.initScanner(selectedDevice.deviceId);
  },

  setupDetection() {
    this.detectedResults = [];
    Quagga.offDetected();

    Quagga.onDetected((result) => {
      const code = result?.codeResult?.code;
      if (!code || this.foundCode) return;

      this.detectedResults.push(code);

      // Keep last 5 results
      if (this.detectedResults.length > 5) {
        this.detectedResults.shift();
      }

      // Check for 3 identical results
      const occurrences = this.detectedResults.filter(c => c === code).length;
      if (occurrences >= 3) {
        this.foundCode = true;
        this.$refs.barcodeInput.value = code;
        this.$refs.barcodeInput.dispatchEvent(new Event('input', { bubbles: true }));
        setTimeout(() => this.destroy(), 2500);
      }
    });
  },

  destroy() {
    if (this.isInitialized) {
      Quagga.stop();
    }

    this.isInitialized = false;
    this.startedCamera = false;
    this.foundCode = false;
    this.devices = [];
    this.currentDeviceIndex = 0;
    this.detectedResults = [];
  }
}));