// Import styles
import '../css/app.css';

// Import necessary libraries and scripts
import './bootstrap';
import './alution-ui/alution-ui';
import './echo.js';
import './app/slide-over';
import './app/confirmation';
import './app/notifications';
import './app/page-title-handler';
import './app/components/livewire/dashboard';
import './app/time-stamp-slider';
import './app/table-builder';
import './app/page-exceptions';
import './app/page-notifications';

import Quagga from 'quagga';
window.Quagga = Quagga;

import SignaturePad from 'signature_pad';
window.SignaturePad = SignaturePad;

import ApexCharts from 'apexcharts';
window.ApexCharts = ApexCharts;

// Optional: Livewire Sortable
import 'livewire-sortable';

// Media and other components
import stopwatch from './app/stopwatch';
import audioPlayer from './app/audio-player';
import videoPlayer from './app/video-player';

// Initialize Alpine.js components
Alpine.data("stopwatch", stopwatch);
Alpine.data("audioPlayer", audioPlayer);
Alpine.data("videoPlayer", videoPlayer);

// Optional: Custom library (e.g., Swapy)
/*
import { createSwapy } from "swapy";

const container = document.querySelector('.container');
if (container) {
  window.swapy = createSwapy(container, {
    animation: 'dynamic',
    continuousMode: true,
  });

  swapy.onSwap((i) => {
    console.log('Swapped index:', i);
  });
}
*/